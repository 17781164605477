export default [
  {
    path: '/activity/single',
    component: () => import('@/views/pages/activity/double-eleven/index'),
    meta: {
      permission: false,
      title: '双11活动页',
    },
    children: [
      {
        path: 'days',
        name: 'Days',
        component: () => import('@/views/pages/activity/double-eleven/home'),
      },
      {
        path: 'vip',
        name: 'Vip',
        component: () => import('@/views/pages/activity/double-eleven/vip'),
      },
    ],
  },
  {
    path: '/activity/package',
    component: () => import('@/views/pages/activity/package/index'),
    meta: {
      permission: false,
      title: '双11活动页',
    },
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/pages/activity/package/home'),
      },
      {
        path: 'activation',
        name: 'Activation',
        component: () => import('@/views/pages/activity/package/activation'),
      },
    ],
  },
  {
    path: '/activity/annual/home',
    component: () => import('@/views/pages/activity/annual-statement/home'),
  },
  {
    path: '/activity/annual/slider',
    component: () => import('@/views/pages/activity/annual-statement/slider'),
  },
  {
    path: '/activity/annual/result',
    component: () => import('@/views/pages/activity/annual-statement/result'),
  },
  {
    path: '/activity/annual/share',
    component: () => import('@/views/pages/activity/annual-statement/share'),
  },
  {
    path: '/activity/security',
    component: () => import('@/views/pages/activity/security/index'),
  },
  {
    path: '/activity/nyd',
    component: () => import('@/views/pages/activity/nyd/index'),
  },
];
