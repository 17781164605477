<template>
  <transition name="toast-fade" @after-leave="onAfterLeave">
    <div class="yy-toast" v-if="visible" :style="style"
      :class="className">
      <div class="icon-image" v-if="icon">
        <img :src="icon" alt="">
      </div>
      <div class="content">{{ content }}</div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'YyToast',
  data() {
    return {
      visible: false,
      timer: null,
      content: '',
      duration: 3000,
      style: {},
      className: '',
      icon: '',
    };
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    clearTimer() {
      clearTimeout(this.timer);
    },
    startTimer() {
      if (this.duration > 0) {
        this.timer = setTimeout(() => {
          if (!this.closed) {
            this.visible = false;
          }
        }, this.duration);
      }
    },
    onAfterLeave() {
      this.$destroy(true);
      this.$el.parentNode.removeChild(this.$el);
    },
  },
};
</script>
<style scoped lang="scss">
.toast-fade-enter-active {
  -webkit-animation: boxfade 1s ease;
  animation: boxfade 1s ease;
  opacity: 1;
}
.toast-fade-enter,
.toast-fade-leave-to {
  opacity: 0;
}
.yy-toast {
  position: fixed;
  width: 8rem;
  margin-left: -4rem;
  top: 50%;
  left: 50%;
  // transform: translateY(-50%);
  z-index: 10001;
  border-radius: 0.133333rem;
  background: #000;
  color: #fff;
  text-align: center;
  font-size: 0.4rem;
  line-height: 0.666666rem;
  padding: 0.266666rem 0;
  .icon-image {
    width: 66px;
    margin: 40px auto 10px auto ;
    > img {
      width: 100%;
    }
  }
}
</style>
