import Vue from 'vue';
import Toast from './main.vue';

const ToastBox = Vue.extend(Toast);

Toast.install = function (options, type) {
  if (!options) {
    options = {
      content: '',
    };
  }
  if (typeof options === 'string' || typeof options === 'number') {
    options = {
      content: options,
    };
    if (type !== undefined && options !== null) {
      options.type = type;
    }
  }

  let instance = new ToastBox({
    data: options,
  }).$mount();
  // console.log(instance.$el);
  document.body.appendChild(instance.$el);

  Vue.nextTick(() => {
    instance.visible = true;
  });
};

export default Toast;
