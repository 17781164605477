const validForbidDirective = {
  name: 'validForbid',
  directive(el, binding) {
    const expression = binding.value;
  },
};

export default {
  install(Vue) {
    Vue.directive(validForbidDirective.name, validForbidDirective.directive);
  },
};
