<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      replaceUrl: '', // 记录一开始进入的页面
    };
  },
  mounted() {
  },
  methods: {
  },
  destroyed() {},
};
</script>

<style lang="scss">
@import "./styles/reset.scss";
@import "./styles/global.scss";
@import "./styles/animate.css";

#app {
  height: 100%;
}
body {
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: costant(safe-area-inset-bottom);
}
</style>
