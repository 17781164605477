import Vue from 'vue';
import VueRouter from 'vue-router';
import { configRouter } from './router.config';
import activityRouter from './activity';
import routerConfig from './router';

const originalReplace = VueRouter.prototype.replace;
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

let routes = [
  {
    // 会员免费领页面
    path: '/incentiveVideo',
    name: 'IncentiveVideo',
    component: () => import('@/views/pages/IncentiveVideo'),
    meta: {
      permission: false,
      title: '会员免费领',
    },
  },
  {
    path: '/leaflet',
    component: () => import('@/views/pages/leaflet/index'),
    meta: {
      permission: false,
      title: '浦发银行海报这啊',
    },
    children: [
      {
        path: 'poster',
        name: 'Poster',
        component: () => import('@/views/pages/leaflet/poster'),
      },
      {
        path: 'newPoster',
        name: 'NewPoster',
        component: () => import('@/views/pages/leaflet/newPoster'),
      },
      {
        path: 'jump',
        name: 'Jump',
        component: () => import('@/views/pages/leaflet/jump'),
      },
      {
        path: 'pufa',
        name: 'Pufa',
        component: () => import('@/views/pages/leaflet/pufa'),
      },
      {
        path: 'pufa/rule',
        name: 'PufaRule',
        component: () => import('@/views/pages/leaflet/pufa-rule'),
      },
      {
        path: 'huaxia',
        name: 'Huaxia',
        component: () => import('@/views/pages/leaflet/huaxia'),
      },
      {
        path: 'huaxia1',
        name: 'Huaxia1',
        component: () => import('@/views/pages/leaflet/huaxia1'),
      },
      {
        path: 'huaxia2',
        name: 'Huaxia2',
        component: () => import('@/views/pages/leaflet/huaxia2'),
      },
      {
        path: 'huaxia3',
        name: 'Huaxia3',
        component: () => import('@/views/pages/leaflet/huaxia3'),
      },
    ],
  },
  {
    path: '/backups',
    component: () => import('@/views/pages/backups/index'),
    meta: {
      permission: false,
      title: '数据备份引导页',
    },
    children: [
      {
        path: 'guide',
        name: 'Guide',
        component: () => import('@/views/pages/backups/guide'),
      },
    ],
  },
  {
    path: '/orange',
    component: () => import('@/views/pages/orange/index'),
    meta: {
      permission: false,
      title: '橘子授权页',
    },
    children: [
      {
        path: 'author',
        name: 'Author',
        component: () => import('@/views/pages/orange/author'),
      },
    ],
  },
  {
    path: '/task',
    component: () => import('@/views/pages/task/index'),
    meta: {
      permission: false,
      title: '任务中心',
    },
    children: [
      {
        path: 'center',
        name: 'Center',
        component: () => import('@/views/pages/task/center'),
      },
      {
        path: 'challenge',
        name: 'Challenge',
        component: () => import('@/views/pages/task/challenge'),
      },
    ],
  },
  {
    path: '/gold',
    component: () => import('@/views/pages/gold-bean/index'),
    meta: {
      permission: false,
      title: '金豆乐园',
    },
    children: [
      {
        path: 'eden',
        name: 'Eden',
        component: () => import('@/views/pages/gold-bean/eden'),
      },
      {
        path: 'rule',
        name: 'Rule',
        component: () => import('@/views/pages/gold-bean/rule'), // 活动规则
      },
      {
        path: 'rule1',
        name: 'Rule1',
        component: () => import('@/views/pages/gold-bean/rule1'), // 抽奖活动规则
      },
      {
        path: 'prize',
        name: 'Prize',
        component: () => import('@/views/pages/gold-bean/prize'), // 我的奖品
      },
      {
        path: 'details',
        name: 'Details',
        component: () => import('@/views/pages/gold-bean/details'), // 我的奖品
      },
      {
        path: 'record',
        name: 'Record',
        component: () => import('@/views/pages/gold-bean/record'), // 兑换记录
        meta: {
          noBack: true,
        },
      },
      {
        path: 'address',
        name: 'Address',
        component: () => import('@/views/pages/gold-bean/address'), // 收货地址
      },
      {
        path: 'editAddress',
        name: 'Address',
        component: () => import('@/views/pages/gold-bean/addAddress'), // 编辑收货地址
      },
      {
        path: 'order',
        name: 'Order',
        component: () => import('@/views/pages/gold-bean/order'), // 确认收货地址
      },
      {
        path: 'newOrder',
        name: 'NewOrder',
        component: () => import('@/views/pages/gold-bean/newOrder'), // 确认收货地址
      },
    ],
  },
  {
    path: '/gold/draw/prize',
    component: () => import('@/views/pages/gold-bean/draw/prize'),
  },
  {
    path: '/gold/draw/exchange',
    component: () => import('@/views/pages/gold-bean/draw/exchange'),
  },
  {
    path: '/gold1',
    component: () => import('@/views/pages/gold-bean/index'),
    meta: {
      permission: false,
      title: '金豆乐园',
    },
    children: [
      {
        path: 'eden',
        name: 'Eden',
        component: () => import('@/views/pages/gold-bean/eden'),
      },
      {
        path: 'rule',
        name: 'Rule',
        component: () => import('@/views/pages/gold-bean/rule'), // 活动规则
      },
      {
        path: 'prize',
        name: 'Prize',
        component: () => import('@/views/pages/gold-bean/prize'), // 我的奖品
      },
      {
        path: 'details',
        name: 'Details',
        component: () => import('@/views/pages/gold-bean/details'), // 我的奖品
      },
      {
        path: 'record',
        name: 'Record',
        component: () => import('@/views/pages/gold-bean/record'), // 兑换记录
        meta: {
          noBack: true,
        },
      },
      {
        path: 'address',
        name: 'Address',
        component: () => import('@/views/pages/gold-bean/address'), // 收货地址
      },
      {
        path: 'editAddress',
        name: 'Address',
        component: () => import('@/views/pages/gold-bean/addAddress'), // 编辑收货地址
      },
      {
        path: 'order',
        name: 'Order',
        component: () => import('@/views/pages/gold-bean/order'), // 确认收货地址
      },
      {
        path: 'newOrder',
        name: 'NewOrder',
        component: () => import('@/views/pages/gold-bean/newOrder'), // 确认收货地址
      },
    ],
  },
  {
    path: '/single',
    component: () => import('@/views/pages/single-day/index'),
    meta: {
      permission: false,
      title: '双11活动页',
    },
    children: [
      {
        path: 'days',
        name: 'Days',
        component: () => import('@/views/pages/single-day/activity'),
      },
    ],
  },
  {
    path: '/intro',
    component: () => import('@/views/pages/intro/index'),
    meta: {
      permission: false,
      title: '双11活动页',
    },
    children: [
      {
        path: 'account',
        name: 'Account',
        component: () => import('@/views/pages/intro/account'),
      },
      {
        path: 'bill',
        name: 'Bill',
        component: () => import('@/views/pages/intro/bill'),
      },
      {
        path: 'explain',
        name: 'Explain',
        component: () => import('@/views/pages/intro/explain'),
      },
      {
        path: 'wealth',
        name: 'Wealth',
        component: () => import('@/views/pages/intro/wealth'),
      },
      {
        path: 'medal',
        name: 'Medal',
        component: () => import('@/views/pages/intro/medal'),
      },
      {
        path: 'signIn',
        name: 'SignIn',
        component: () => import('@/views/pages/intro/signIn'),
      },
    ],
  },
  {
    path: '/find',
    component: () => import('@/views/pages/find/index'),
    meta: {
      permission: false,
      title: '发现页',
    },
    children: [
      {
        path: 'loan',
        name: 'Loan',
        component: () => import('@/views/pages/find/loan'),
      },
    ],
  },
  {
    path: '/cicc',
    component: () => import('@/views/pages/cicc/index'),
    meta: {
      permission: false,
      title: '中金理财活动页',
    },
  },
  {
    path: '/fate',
    component: () => import('@/views/pages/fate/index'),
    meta: {
      permission: false,
      title: '流年运势',
    },
  },
  {
    path: '/loovee',
    component: () => import('@/views/pages/loovee/index'),
    meta: {
      permission: false,
      title: '在线抓娃娃',
    },
  },
  {
    path: '/xz',
    component: () => import('@/views/pages/xz/index'),
    meta: {
      permission: false,
      title: '小账管家',
    },
    children: [
      {
        path: 'help',
        name: 'Help',
        component: () => import('@/views/pages/xz/help/help'),
      },
      {
        path: 'details',
        name: 'Details',
        component: () => import('@/views/pages/xz/help/details'),
      },
      {
        path: 'feedback',
        name: 'Feedback',
        component: () => import('@/views/pages/xz/help/feedback'),
      },
      {
        path: 'bill',
        name: 'Bill',
        component: () => import('@/views/pages/xz/intro/bill'),
      },
    ],
  },
  {
    path: '/blind',
    component: () => import('@/views/pages/blind-box/index'),
    meta: {
      permission: false,
      title: '“鱼”你拆盲盒活动',
    },
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/pages/blind-box/home'),
      },
      {
        path: 'prize',
        name: 'Prize',
        component: () => import('@/views/pages/blind-box/prize'),
      },
    ],
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('@/views/pages/payment'),
  },
  {
    path: '/record/newImport',
    name: 'NewImport',
    component: () => import('@/views/pages/record/import'),
  },
  {
    path: '/record/import',
    name: 'Import',
    component: () => import('@/views/pages/record/import'),
  },
  {
    path: '/record/guide',
    name: 'Guide',
    component: () => import('@/views/pages/record/guide'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/pages/register/index'),
  },
  {
    path: '/activity/saveup',
    name: 'Saveup',
    component: () => import('@/views/pages/activity/saveup/index'),
  },
  {
    path: '/activity/promoteSales',
    name: 'PromoteSales',
    component: () => import('@/views/pages/activity/promote-sales/index'),
  },
  {
    path: '/question/vip',
    name: 'QuestionVip',
    component: () => import('@/views/pages/question/vip'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/pages/404'),
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/NotFound'),
  },
].concat(activityRouter);
routes = routes.concat(configRouter).concat(routerConfig);
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
