import Vue from 'vue';
import App from './App.vue';
import router from './router';
// import './permission';
// import Element from './element';
// import 'element-ui/lib/theme-chalk/index.css';
import Toast from '@/components/toast';
import Valid from '@/utils/directives';
// import VConsole from 'vconsole';

// 解决移动端click事件300毫秒延迟方法
// import FastClick from 'fastclick';

// if ('addEventListener' in document) {
//   document.addEventListener(
//     'DOMContentLoaded',
//     () => {
//       FastClick.attach(document.body);
//     },
//     false,
//   );
// }

// new VConsole();

Vue.prototype.$toast = Toast.install;

Vue.config.productionTip = false;
// Vue.use(Element);
Vue.use(Valid);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
